import React from "react"

import PageBody from "../components/page-body/pageBody"
import SEO from "../components/seo"

import Banner from "../components/banner/banner"
import bg from "../images/banners/program-components.jpg"
import BorderTitle from "../components/border-title/borderTitle"
import ComponentDescription from "../components/component-description/componentDescription"
import { componentsYearTwo } from "../models/programComponents"
import { programComponentsPageYearTwo } from "../utils/text"

import "./program-components-year-two.scss"

var ProgramComponentsYearTwo = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="program-components-page">
      <Banner
        src={bg}
        title={programComponentsPageYearTwo.banner.title}
        subTitle={programComponentsPageYearTwo.banner.subtitle}
        height="27.5rem"
      />
      <PageBody className="page">
        <BorderTitle>{programComponentsPageYearTwo.body.title}</BorderTitle>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Habitant
          morbi tristique senectus et netus et malesuada. Metus aliquam eleifend
          mi in nulla posuere. Enim tortor at auctor urna nunc. Dignissim diam
          quis enim lobortis scelerisque fermentum dui faucibus. Cursus eget
          nunc scelerisque viverra mauris in aliquam. Cras semper auctor neque
          vitae tempus quam pellentesque. Mi eget mauris pharetra et. Eget nunc
          lobortis mattis aliquam faucibus purus.
        </p> */}
        <div className="components">
          {componentsYearTwo.map(component => (
            <ComponentDescription
              key={component.title}
              card
              className="component"
              componentsDir="components-year-two"
              icon={component.icon}
              pageName={component.pageName}
              title={component.title}
              description={component.description}
            />
          ))}
        </div>
      </PageBody>
    </div>
  </>
)

export default ProgramComponentsYearTwo
